import React from "react";

export default function Login() {
  return (
    <div className="cs-modal" data-modal="login">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_95 cs-height_lg_70"></div>
          <div className="cs-login">
            <div className="cs-login_left">
              <img src="../../../img/about_img_1.png" alt="Login Thumb" />
            </div>
            <div className="cs-login_right">
              <form className="cs-login_form">
                <h2>Login in to your posing account</h2>
                <div className="cs-height_30 cs-height_lg_30"></div>
                <input
                  type="text"
                  className="cs-form_field cs-border_color"
                  placeholder="Email address"
                />
                <div className="cs-height_20 cs-height_lg_20"></div>
                <input
                  type="password"
                  className="cs-form_field cs-border_color"
                  placeholder="Password"
                />
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-login_meta">
                  <div>
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>Remember me</label>
                    </div>
                  </div>
                  <div>
                    <span
                      className="cs-text_btn cs-modal_btn"
                      data-modal="forgot"
                    >
                      <span>Forgot Password?</span>
                    </span>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <button className="cs-btn cs-size_md w-100">
                  <span>Login</span>
                </button>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <p className="cs-m0">
                  Don't have an account?
                  <span
                    className="cs-text_btn cs-modal_btn"
                    data-modal="register"
                  >
                    <span>Register</span>
                  </span>
                </p>
                <div className="cs-height_30 cs-height_lg_30"></div>
                <div className="cs-or">
                  <span>or</span>
                </div>
                <div className="cs-height_40 cs-height_lg_30"></div>
                <div className="cs-social_btns cs-style2">
                  <a href="#" target="_blank" className="cs-center cs-color1">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.3187 8.34992C16.329 7.78817 16.2694 7.22728 16.1411 6.67969H8.31738V9.71004H12.9094C12.8229 10.2417 12.6273 10.7506 12.3343 11.206C12.0413 11.6614 11.657 12.0539 11.2047 12.3596V12.4629L13.6785 14.3399L13.8506 14.3562C15.4248 12.9335 16.3326 10.8362 16.3326 8.34992"
                        fill="currentColor"
                      />
                      <path
                        d="M8.3182 16.3333C10.567 16.3333 12.4577 15.6097 13.8375 14.3556L11.2055 12.359C10.3535 12.9194 9.34397 13.2048 8.3182 13.1751C7.26397 13.1691 6.23842 12.838 5.38719 12.2286C4.53597 11.6192 3.90232 10.7625 3.57621 9.78021H3.47904L0.905385 11.7306L0.87207 11.8231C1.56522 13.1759 2.62872 14.3132 3.94369 15.1077C5.25865 15.9023 6.77328 16.3228 8.3182 16.3224"
                        fill="currentColor"
                      />
                      <path
                        d="M3.58123 9.78285C3.39838 9.26292 3.30457 8.71693 3.3036 8.16701C3.30991 7.61843 3.40359 7.07419 3.58123 6.55392V6.4451L0.977035 4.46204L0.890987 4.50285C0.305173 5.64191 0 6.89966 0 8.17517C0 9.45068 0.305173 10.7084 0.890987 11.8475L3.59513 9.79643"
                        fill="currentColor"
                      />
                      <path
                        d="M8.3182 3.15874C9.51172 3.14118 10.6659 3.57696 11.5387 4.37471L13.893 2.12507C12.3845 0.738085 10.3868 -0.0232452 8.3182 0.000541035C6.77299 0.000338841 5.25817 0.421321 3.94318 1.21638C2.62818 2.01144 1.56484 3.14925 0.87207 4.50256L3.56509 6.55363C3.89512 5.57159 4.5313 4.71575 5.38401 4.10671C6.23672 3.49766 7.263 3.16608 8.3182 3.15874Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="#" target="_blank" className="cs-center cs-color2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.98623 8.86489e-07C6.08929 0.0017225 4.2548 0.695882 2.81063 1.95834C1.36645 3.2208 0.406756 4.9693 0.103097 6.89129C-0.200562 8.81328 0.171598 10.7835 1.15306 12.4497C2.13452 14.1159 3.66128 15.3695 5.46047 15.9865C5.85983 16.0616 6.00621 15.8084 6.00621 15.5917C6.00621 15.3749 6.00621 14.7482 6.00621 14.0636C3.78364 14.5593 3.31323 13.0957 3.31323 13.0957C2.94942 12.1471 2.42671 11.896 2.42671 11.896C1.70119 11.3874 2.48102 11.3981 2.48102 11.3981C3.28391 11.4561 3.70628 12.2415 3.70628 12.2415C4.41926 13.497 5.57552 13.1343 6.03132 12.924C6.06674 12.5076 6.24709 12.1183 6.53938 11.8273C4.76426 11.6127 2.89715 10.9173 2.89715 7.77327C2.88686 6.95809 3.18172 6.16998 3.72093 5.57139C3.47672 4.8644 3.50436 4.08841 3.79826 3.40163C3.79826 3.40163 4.46943 3.18701 5.99574 4.2429C7.30617 3.87376 8.68931 3.87376 9.99974 4.2429C11.524 3.18057 12.193 3.40163 12.193 3.40163C12.4882 4.08798 12.5166 4.86423 12.2725 5.57139C12.8118 6.16972 13.1061 6.95818 13.0941 7.77327C13.0941 10.9238 11.2249 11.617 9.44563 11.8209C9.73208 12.0763 9.98718 12.5742 9.98718 13.3404C9.98718 14.437 9.98718 15.3212 9.98718 15.5917C9.98718 15.8063 10.1314 16.0659 10.535 15.9844C12.3355 15.3685 13.8638 14.115 14.8462 12.4482C15.8286 10.7813 16.2009 8.81009 15.8966 6.88721C15.5922 4.96432 14.6311 3.21536 13.1852 1.95347C11.7392 0.691575 9.90298 -0.000904397 8.00507 8.86489e-07H7.98623Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="#" target="_blank" className="cs-center cs-color3">
                    <svg
                      width="8"
                      height="16"
                      viewBox="0 0 8 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.8194 2.64177H8V0.194847C7.30745 0.0755463 6.6076 0.0104213 5.90608 0C3.76786 0 2.37343 1.3769 2.37343 3.86767V6.05804H0V8.93201H2.36884V16H5.2921V8.93201H7.47003L7.88545 6.05804H5.28904V4.19403C5.29668 3.40816 5.65865 2.64177 6.8194 2.64177Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="#" target="_blank" className="cs-center cs-color4">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.319909 5.44236L3.65951 5.38763L3.83385 16.276L0.494274 16.3328L0.319909 5.44236ZM1.90356 0.00028458C2.28658 -0.00632752 2.66294 0.102414 2.985 0.312783C3.30707 0.523153 3.56037 0.825679 3.71289 1.1821C3.86542 1.53852 3.9103 1.93284 3.84185 2.31514C3.77341 2.69744 3.59474 3.05054 3.32841 3.32981C3.06208 3.60908 2.72006 3.80197 2.34563 3.88406C1.97121 3.96615 1.5812 3.93375 1.22493 3.79098C0.868658 3.6482 0.562148 3.40144 0.344156 3.08194C0.126164 2.76244 0.00650348 2.38456 0.00028934 1.99606C-0.00410782 1.73821 0.0416518 1.48201 0.134953 1.24211C0.228253 1.00221 0.367265 0.783325 0.544035 0.597964C0.720806 0.412604 0.93185 0.26441 1.16513 0.161854C1.39842 0.0592974 1.64934 0.00439659 1.90356 0.00028458Z"
                        fill="currentColor"
                      />
                      <path
                        d="M5.75391 5.35233L8.95236 5.2997L8.97726 6.78813H9.02292C9.45256 5.92498 10.5277 5.00288 12.1529 4.97762C15.5298 4.92078 16.1898 7.16707 16.2376 10.0997L16.333 16.0723L12.9976 16.127L12.9125 10.8302C12.8918 9.56706 12.844 7.94392 11.1317 7.97128C9.41936 7.99865 9.1516 9.38179 9.17443 10.8028L9.26161 16.1881L5.92825 16.2428L5.75391 5.35233Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className="cs-height_100 cs-height_lg_70"></div>
        </div>
      </div>
    </div>
  );
}
