import React from "react";

export default function Header() {
  return (
    <header className="cs-site_header cs-style1 cs-sticky-header cs-primary_color cs-white_bg">
      <div className="cs-main_header">
        <div className="container">
          <div className="cs-main_header_in">
            <div className="cs-main_header_left">
              <a className="cs-site_branding cs-accent_color" href="/">
                <img  src="../../../img/logo.png"   alt="Logo" />
              </a>
            </div>
            <div className="cs-main_header_center">
              <div className="cs-nav">
                <ul className="cs-nav_list">
                  <li>
                    <a href="#home" className="cs-smoth_scroll">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" className="cs-smoth_scroll">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#feature" className="cs-smoth_scroll">
                      Feature
                    </a>
                  </li>
                  
                 
                  <li>
                    <a href="#contact" className="cs-smoth_scroll">
                      Contacts
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cs-main_header_right ">
           
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
