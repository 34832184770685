import React from "react";

export default function AllFeature() {
  return (
    <section className="cs-bg" data-src="../../../img/feature_bg.svg">
      <div className="cs-height_135 cs-height_lg_0"></div>
      <div id="feature">
        <div className="cs-height_95 cs-height_lg_70"></div>
        <div className="container">
          <div className="cs-seciton_heading cs-style1 text-center">
            <div
              className="cs-section_subtitle wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              POS Features
            </div>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <h3 className="cs-section_title">Available features</h3>
          </div>
          <div className="cs-height_50 cs-height_lg_40"></div>
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_5.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Effortless card</h3>
                  <div className="cs-iconbox_subtitle">
                  Streamlining transactions with ease, our card services empower businesses of all sizes for effortless growth
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_6.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Software accuracy</h3>
                  <div className="cs-iconbox_subtitle">
                  Our commitment to precision ensures software accuracy, empowering seamless operations and reliable performance
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_7.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Customization</h3>
                  <div className="cs-iconbox_subtitle">
                  Unlocking potential through personalized customization, we empower businesses with tailored solutions for optimal performance
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_8.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Customer data</h3>
                  <div className="cs-iconbox_subtitle">
                  Our robust system ensures safe and efficient management of customer data, safeguarding privacy and enhancing trust
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_9.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Seamless checkout</h3>
                  <div className="cs-iconbox_subtitle">
                  Streamlining the checkout process, our solution offers a seamless experience,customer satisfaction 
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_10.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">High speed process</h3>
                  <div className="cs-iconbox_subtitle">
                  Accelerating operations with high-speed efficiency, our system optimizes processes for seamless performance
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
          </div>
        </div>
        <div className="cs-height_75 cs-height_lg_45"></div>
      </div>
    </section>
  );
}
