import React from "react";

export default function Footer() {
  return (
    <footer className="cs-footer">
      <div className="cs-height_75 cs-height_lg_70"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item">
              <div className="cs-footer_widget_text">
              <img src="../../../img/f.png"  alt="Logo" />
                <p>
                Empowering businesses with seamless payment solutions. Trust GreatBritishPayment for efficient transactions and reliable service
                </p>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
              <div className="cs-social_btns cs-style1">
                <a href="#">
                  <img src="../../../img/facebook.svg" alt="Facebook" />
                </a>
                <a href="#">
                  <img src="../../../img/twitter.svg" alt="Twitter" />
                </a>
                <a href="#">
                  <img src="../../../img/instagram.svg" alt="Instagram" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item widget_nav_menu">
              <h2 className="cs-widget_title">Available POS</h2>
              <ul className="menu">
                <li>
                  <a href="#">Food Delivery</a>
                </li>
                <li>
                  <a href="#">Furniture Store</a>
                </li>
                <li>
                  <a href="#">Coffee Shop</a>
                </li>
                <li>
                  <a href="#">Clothing Store</a>
                </li>
                <li>
                  <a href="#">eCommerce</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item widget_nav_menu">
              <h2 className="cs-widget_title">Company</h2>
              <ul className="menu">
                <li>
                  <a href="#">Fast Processing</a>
                </li>
                <li>
                  <a href="#">Cheap Rates</a>
                </li>
                <li>
                  <a href="#">Secure Infrastructures</a>
                </li>
                <li>
                  <a href="#">Cooperative Staff</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="cs-footer_item widget_nav_menu">
              <h2 className="cs-widget_title">Your Partner in Success</h2>
              <div>With our comprehensive payment services, we're dedicated to supporting UK businesses of all sizes. Join our community and elevate your business today</div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="cs-height_40 cs-height_lg_30"></div>
      <div className="cs-copyright text-center">
        
      </div>
    </footer>
  );
}
