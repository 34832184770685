import React from "react";

export default function About() {
  return (
    <section id="about" className="cs-gradient_bg_1">
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="row align-items-center flex-column-reverse-lg">
          <div
            className="col-xl-6 wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="cs-left_full_width cs-space110">
              <div className="cs-left_sided_img">
                <img src="../../../img/about_img_1.png" alt="About" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="cs-height_0 cs-height_lg_40"></div>
            <div className="cs-seciton_heading cs-style1">
              <div className="cs-section_subtitle">About Us</div>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <h3 className="cs-section_title">
                
Empowering businesses with innovative POS solutions{" "}
              </h3>
            </div>
            <div className="cs-height_20 cs-height_lg_20"></div>
            <p>
            At GreatBritishPayment, we're committed to revolutionizing the way businesses manage their point-of-sale operations. With a focus on innovation, reliability, and customer satisfaction, we strive to provide cutting-edge payment solutions tailored to meet the diverse needs of modern businesses.
            </p>
            <div className="cs-height_15 cs-height_lg_15"></div>
            <div className="cs-list_1_wrap">
              <ul className="cs-list cs-style1 cs-mp0">
                <li>
                  <span className="cs-list_icon">
                    <img src="../../../img/icons/tick.svg" alt="Tick" />
                  </span>
                  <div className="cs-list_right">
                    <h3>Our journey started with a vision</h3>
                    <p>
                    to provide businesses with seamless payment solutions for enhanced operations and growth. Since then, we've remained dedicated to this mission, continuously evolving to adapt to the changing landscape of commerce.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="cs-list_icon">
                    <img src="../../../img/icons/tick.svg" alt="Tick" />
                  </span>
                  <div className="cs-list_right">
                    <h3>Tailored Solutions for Unique Business Needs</h3>
                    <p>
                    Our dedication to customer satisfaction sets us apart. We understand each business is unique, tailoring solutions to specific needs. From secure payments to advanced analytics, our comprehensive features empower businesses of all sizes
                    </p>
                  </div>
                </li>
              </ul>
              <div
                className="cs-list_img wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="cs-height_135 cs-height_lg_0"></div>
    </section>
  );
}
