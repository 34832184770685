import React from "react";

export default function Testimonials() {
  return (
    <section className="cs-gradient_bg_1">
      <div className="cs-height_95 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div
            className="cs-section_subtitle wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            Testimonials
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <h3 className="cs-section_title">What our client’s say</h3>
        </div>
        <div className="cs-height_50 cs-height_lg_40"></div>
        <div className="cs-slider cs-style1 cs-gap-24">
          <div
            className="cs-slider_container"
            data-autoplay="0"
            data-loop="1"
            data-speed="600"
            data-fade-slide="0"
            data-slides-per-view="responsive"
            data-xs-slides="1"
            data-sm-slides="2"
            data-md-slides="3"
            data-lg-slides="3"
            data-add-slides="3"
          >
            <div className="cs-slider_wrapper">
              <div className="cs-slide">
                <div className="cs-testimonial cs-style1">
                  <div className="cs-testimonial_text">
                  "GreatBritishPayment exceeded our expectations! Their seamless payment solutions have transformed our business operations. Highly recommend their services!"
                  </div>
                  <div className="cs-testimonial_meta">
                    <div className="cs-avatar">
                      <img src="../../../img/avatar_1.png" alt="Avatar" />
                      <div className="cs-quote cs-center">
                        <svg
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.9033 0.470251C17.974 0.470231 18.0431 0.492568 18.1019 0.534429C18.1606 0.576288 18.2064 0.635782 18.2333 0.705356L18.9732 2.62083C19.0075 2.70954 19.0089 2.80875 18.9772 2.89853C18.9455 2.98831 18.8831 3.06201 18.8024 3.1048C17.5346 3.77738 16.6023 4.83665 16.0311 6.25297C15.8289 6.75453 15.6825 7.27956 15.595 7.81733L17.569 7.81733C17.6636 7.81733 17.7544 7.85732 17.8213 7.92851C17.8882 7.9997 17.9258 8.09624 17.9258 8.19692L17.9258 14.6207C17.9258 14.7213 17.8882 14.8179 17.8213 14.8891C17.7544 14.9603 17.6636 15.0002 17.569 15.0002L10.2806 15.0002C10.1859 15.0002 10.0952 14.9603 10.0283 14.8891C9.96136 14.8179 9.92377 14.7213 9.92377 14.6207L9.92377 10.2215C9.92329 8.5455 10.3244 6.897 11.0891 5.4317C11.8539 3.9664 12.9571 2.73265 14.2946 1.84695C15.2002 1.24811 16.1969 0.821584 17.24 0.586498C17.6221 0.499905 17.8567 0.473809 17.8665 0.472623C17.8787 0.47112 17.891 0.470328 17.9033 0.470251ZM18.19 2.59094L17.6794 1.26856C17.5984 1.28351 17.501 1.3032 17.3895 1.32859C13.4762 2.2142 10.6374 5.95429 10.6374 10.2215L10.6374 14.2411L17.2122 14.2411L17.2122 8.5765L15.1902 8.5765C15.1417 8.57658 15.0936 8.56612 15.049 8.54576C15.0044 8.52541 14.9642 8.49559 14.9308 8.45813C14.8974 8.42066 14.8715 8.37634 14.8548 8.32788C14.838 8.27942 14.8308 8.22783 14.8334 8.17628C14.8354 8.13571 14.8892 7.17133 15.3635 5.98228C15.6341 5.29767 16.0027 4.66176 16.4562 4.09717C16.9467 3.49086 17.5336 2.98102 18.19 2.59094Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.42661 0.470251C8.4973 0.470278 8.56638 0.492638 8.6251 0.534494C8.68382 0.576352 8.72953 0.635819 8.75643 0.705356L9.49636 2.62083C9.53062 2.70954 9.53205 2.80875 9.50036 2.89853C9.46867 2.98831 9.40621 3.06201 9.32554 3.1048C8.05777 3.77738 7.12539 4.83665 6.55428 6.25297C6.3522 6.75461 6.20582 7.27961 6.11809 7.81733L8.09211 7.81733C8.18674 7.81733 8.27749 7.85732 8.34441 7.92851C8.41132 7.9997 8.44891 8.09624 8.44891 8.19692L8.44891 14.6207C8.44891 14.7213 8.41132 14.8179 8.34441 14.8891C8.27749 14.9603 8.18674 15.0002 8.09211 15.0002L0.802814 15.0002C0.708182 15.0002 0.617428 14.9603 0.550514 14.8891C0.483601 14.8179 0.446009 14.7213 0.446009 14.6207L0.446009 10.2215C0.442028 7.96623 1.16694 5.77803 2.49922 4.02375C3.8315 2.26946 5.69016 1.05573 7.76363 0.586024C8.14563 0.499431 8.38001 0.473334 8.38982 0.472148C8.40204 0.470806 8.41432 0.470174 8.42661 0.470251ZM8.71339 2.59094L8.2025 1.26856C8.12177 1.28351 8.02409 1.3032 7.91259 1.32859C3.99934 2.2142 1.15962 5.95429 1.15962 10.2215L1.15962 14.2411L7.7353 14.2411L7.7353 8.5765L5.71334 8.5765C5.6648 8.57658 5.61676 8.56612 5.57216 8.54576C5.52755 8.52541 5.48732 8.49559 5.45392 8.45813C5.42052 8.42066 5.39466 8.37634 5.37791 8.32788C5.36117 8.27942 5.35389 8.22783 5.35653 8.17628C5.35854 8.13571 5.41251 7.17133 5.88661 5.98228C6.15719 5.29767 6.5258 4.66177 6.97932 4.09717C7.46979 3.49073 8.05677 2.98087 8.71339 2.59094Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="cs-testimonial_meta_right">
                      <h3>James Alex</h3>
                      <p>Customer</p>
                      <div className="cs-review" data-review="4">
                        <img src="../../../img/icons/stars.svg" alt="Star" />
                        <div className="cs-review_in">
                          <img src="../../../img/icons/stars.svg" alt="Star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-testimonial cs-style1">
                  <div className="cs-testimonial_text">
                  "GreatBritishPayment has been instrumental in streamlining our payment processes. Their support team is responsive and their solutions are top-notch."
                  </div>
                  <div className="cs-testimonial_meta">
                    <div className="cs-avatar">
                      <img src="../../../img/avatar_2.png" alt="Avatar" />
                      <div className="cs-quote cs-center">
                        <svg
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.9033 0.470251C17.974 0.470231 18.0431 0.492568 18.1019 0.534429C18.1606 0.576288 18.2064 0.635782 18.2333 0.705356L18.9732 2.62083C19.0075 2.70954 19.0089 2.80875 18.9772 2.89853C18.9455 2.98831 18.8831 3.06201 18.8024 3.1048C17.5346 3.77738 16.6023 4.83665 16.0311 6.25297C15.8289 6.75453 15.6825 7.27956 15.595 7.81733L17.569 7.81733C17.6636 7.81733 17.7544 7.85732 17.8213 7.92851C17.8882 7.9997 17.9258 8.09624 17.9258 8.19692L17.9258 14.6207C17.9258 14.7213 17.8882 14.8179 17.8213 14.8891C17.7544 14.9603 17.6636 15.0002 17.569 15.0002L10.2806 15.0002C10.1859 15.0002 10.0952 14.9603 10.0283 14.8891C9.96136 14.8179 9.92377 14.7213 9.92377 14.6207L9.92377 10.2215C9.92329 8.5455 10.3244 6.897 11.0891 5.4317C11.8539 3.9664 12.9571 2.73265 14.2946 1.84695C15.2002 1.24811 16.1969 0.821584 17.24 0.586498C17.6221 0.499905 17.8567 0.473809 17.8665 0.472623C17.8787 0.47112 17.891 0.470328 17.9033 0.470251ZM18.19 2.59094L17.6794 1.26856C17.5984 1.28351 17.501 1.3032 17.3895 1.32859C13.4762 2.2142 10.6374 5.95429 10.6374 10.2215L10.6374 14.2411L17.2122 14.2411L17.2122 8.5765L15.1902 8.5765C15.1417 8.57658 15.0936 8.56612 15.049 8.54576C15.0044 8.52541 14.9642 8.49559 14.9308 8.45813C14.8974 8.42066 14.8715 8.37634 14.8548 8.32788C14.838 8.27942 14.8308 8.22783 14.8334 8.17628C14.8354 8.13571 14.8892 7.17133 15.3635 5.98228C15.6341 5.29767 16.0027 4.66176 16.4562 4.09717C16.9467 3.49086 17.5336 2.98102 18.19 2.59094Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.42661 0.470251C8.4973 0.470278 8.56638 0.492638 8.6251 0.534494C8.68382 0.576352 8.72953 0.635819 8.75643 0.705356L9.49636 2.62083C9.53062 2.70954 9.53205 2.80875 9.50036 2.89853C9.46867 2.98831 9.40621 3.06201 9.32554 3.1048C8.05777 3.77738 7.12539 4.83665 6.55428 6.25297C6.3522 6.75461 6.20582 7.27961 6.11809 7.81733L8.09211 7.81733C8.18674 7.81733 8.27749 7.85732 8.34441 7.92851C8.41132 7.9997 8.44891 8.09624 8.44891 8.19692L8.44891 14.6207C8.44891 14.7213 8.41132 14.8179 8.34441 14.8891C8.27749 14.9603 8.18674 15.0002 8.09211 15.0002L0.802814 15.0002C0.708182 15.0002 0.617428 14.9603 0.550514 14.8891C0.483601 14.8179 0.446009 14.7213 0.446009 14.6207L0.446009 10.2215C0.442028 7.96623 1.16694 5.77803 2.49922 4.02375C3.8315 2.26946 5.69016 1.05573 7.76363 0.586024C8.14563 0.499431 8.38001 0.473334 8.38982 0.472148C8.40204 0.470806 8.41432 0.470174 8.42661 0.470251ZM8.71339 2.59094L8.2025 1.26856C8.12177 1.28351 8.02409 1.3032 7.91259 1.32859C3.99934 2.2142 1.15962 5.95429 1.15962 10.2215L1.15962 14.2411L7.7353 14.2411L7.7353 8.5765L5.71334 8.5765C5.6648 8.57658 5.61676 8.56612 5.57216 8.54576C5.52755 8.52541 5.48732 8.49559 5.45392 8.45813C5.42052 8.42066 5.39466 8.37634 5.37791 8.32788C5.36117 8.27942 5.35389 8.22783 5.35653 8.17628C5.35854 8.13571 5.41251 7.17133 5.88661 5.98228C6.15719 5.29767 6.5258 4.66177 6.97932 4.09717C7.46979 3.49073 8.05677 2.98087 8.71339 2.59094Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="cs-testimonial_meta_right">
                      <h3>Aqib Javeed</h3>
                      <p>Customer</p>
                      <div className="cs-review" data-review="5">
                        <img src="../../../img/icons/stars.svg" alt="Star" />
                        <div className="cs-review_in">
                          <img src="../../../img/icons/stars.svg" alt="Star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-testimonial cs-style1">
                  <div className="cs-testimonial_text">
                   
"Choosing GreatBritishPayment was a game-changer for our business. Their innovative payment solutions have significantly improved our efficiency and customer satisfaction."
                  </div>
                  <div className="cs-testimonial_meta">
                    <div className="cs-avatar">
                      <img src="../../../img/avatar_3.png" alt="Avatar" />
                      <div className="cs-quote cs-center">
                        <svg
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.9033 0.470251C17.974 0.470231 18.0431 0.492568 18.1019 0.534429C18.1606 0.576288 18.2064 0.635782 18.2333 0.705356L18.9732 2.62083C19.0075 2.70954 19.0089 2.80875 18.9772 2.89853C18.9455 2.98831 18.8831 3.06201 18.8024 3.1048C17.5346 3.77738 16.6023 4.83665 16.0311 6.25297C15.8289 6.75453 15.6825 7.27956 15.595 7.81733L17.569 7.81733C17.6636 7.81733 17.7544 7.85732 17.8213 7.92851C17.8882 7.9997 17.9258 8.09624 17.9258 8.19692L17.9258 14.6207C17.9258 14.7213 17.8882 14.8179 17.8213 14.8891C17.7544 14.9603 17.6636 15.0002 17.569 15.0002L10.2806 15.0002C10.1859 15.0002 10.0952 14.9603 10.0283 14.8891C9.96136 14.8179 9.92377 14.7213 9.92377 14.6207L9.92377 10.2215C9.92329 8.5455 10.3244 6.897 11.0891 5.4317C11.8539 3.9664 12.9571 2.73265 14.2946 1.84695C15.2002 1.24811 16.1969 0.821584 17.24 0.586498C17.6221 0.499905 17.8567 0.473809 17.8665 0.472623C17.8787 0.47112 17.891 0.470328 17.9033 0.470251ZM18.19 2.59094L17.6794 1.26856C17.5984 1.28351 17.501 1.3032 17.3895 1.32859C13.4762 2.2142 10.6374 5.95429 10.6374 10.2215L10.6374 14.2411L17.2122 14.2411L17.2122 8.5765L15.1902 8.5765C15.1417 8.57658 15.0936 8.56612 15.049 8.54576C15.0044 8.52541 14.9642 8.49559 14.9308 8.45813C14.8974 8.42066 14.8715 8.37634 14.8548 8.32788C14.838 8.27942 14.8308 8.22783 14.8334 8.17628C14.8354 8.13571 14.8892 7.17133 15.3635 5.98228C15.6341 5.29767 16.0027 4.66176 16.4562 4.09717C16.9467 3.49086 17.5336 2.98102 18.19 2.59094Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.42661 0.470251C8.4973 0.470278 8.56638 0.492638 8.6251 0.534494C8.68382 0.576352 8.72953 0.635819 8.75643 0.705356L9.49636 2.62083C9.53062 2.70954 9.53205 2.80875 9.50036 2.89853C9.46867 2.98831 9.40621 3.06201 9.32554 3.1048C8.05777 3.77738 7.12539 4.83665 6.55428 6.25297C6.3522 6.75461 6.20582 7.27961 6.11809 7.81733L8.09211 7.81733C8.18674 7.81733 8.27749 7.85732 8.34441 7.92851C8.41132 7.9997 8.44891 8.09624 8.44891 8.19692L8.44891 14.6207C8.44891 14.7213 8.41132 14.8179 8.34441 14.8891C8.27749 14.9603 8.18674 15.0002 8.09211 15.0002L0.802814 15.0002C0.708182 15.0002 0.617428 14.9603 0.550514 14.8891C0.483601 14.8179 0.446009 14.7213 0.446009 14.6207L0.446009 10.2215C0.442028 7.96623 1.16694 5.77803 2.49922 4.02375C3.8315 2.26946 5.69016 1.05573 7.76363 0.586024C8.14563 0.499431 8.38001 0.473334 8.38982 0.472148C8.40204 0.470806 8.41432 0.470174 8.42661 0.470251ZM8.71339 2.59094L8.2025 1.26856C8.12177 1.28351 8.02409 1.3032 7.91259 1.32859C3.99934 2.2142 1.15962 5.95429 1.15962 10.2215L1.15962 14.2411L7.7353 14.2411L7.7353 8.5765L5.71334 8.5765C5.6648 8.57658 5.61676 8.56612 5.57216 8.54576C5.52755 8.52541 5.48732 8.49559 5.45392 8.45813C5.42052 8.42066 5.39466 8.37634 5.37791 8.32788C5.36117 8.27942 5.35389 8.22783 5.35653 8.17628C5.35854 8.13571 5.41251 7.17133 5.88661 5.98228C6.15719 5.29767 6.5258 4.66177 6.97932 4.09717C7.46979 3.49073 8.05677 2.98087 8.71339 2.59094Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="cs-testimonial_meta_right">
                      <h3>MR CARL</h3>
                      <p>Customer</p>
                      <div className="cs-review" data-review="4.5">
                        <img src="../../../img/icons/stars.svg" alt="Star" />
                        <div className="cs-review_in">
                          <img src="../../../img/icons/stars.svg" alt="Star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-testimonial cs-style1">
                  <div className="cs-testimonial_text">
                  "GreatBritishPayment has revolutionized our payment systems. Their user-friendly solutions and exceptional support have made a huge impact on our business success."
                  </div>
                  <div className="cs-testimonial_meta">
                    <div className="cs-avatar">
                      <img src="../../../img/avatar_1.png" alt="Avatar" />
                      <div className="cs-quote cs-center">
                        <svg
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.9033 0.470251C17.974 0.470231 18.0431 0.492568 18.1019 0.534429C18.1606 0.576288 18.2064 0.635782 18.2333 0.705356L18.9732 2.62083C19.0075 2.70954 19.0089 2.80875 18.9772 2.89853C18.9455 2.98831 18.8831 3.06201 18.8024 3.1048C17.5346 3.77738 16.6023 4.83665 16.0311 6.25297C15.8289 6.75453 15.6825 7.27956 15.595 7.81733L17.569 7.81733C17.6636 7.81733 17.7544 7.85732 17.8213 7.92851C17.8882 7.9997 17.9258 8.09624 17.9258 8.19692L17.9258 14.6207C17.9258 14.7213 17.8882 14.8179 17.8213 14.8891C17.7544 14.9603 17.6636 15.0002 17.569 15.0002L10.2806 15.0002C10.1859 15.0002 10.0952 14.9603 10.0283 14.8891C9.96136 14.8179 9.92377 14.7213 9.92377 14.6207L9.92377 10.2215C9.92329 8.5455 10.3244 6.897 11.0891 5.4317C11.8539 3.9664 12.9571 2.73265 14.2946 1.84695C15.2002 1.24811 16.1969 0.821584 17.24 0.586498C17.6221 0.499905 17.8567 0.473809 17.8665 0.472623C17.8787 0.47112 17.891 0.470328 17.9033 0.470251ZM18.19 2.59094L17.6794 1.26856C17.5984 1.28351 17.501 1.3032 17.3895 1.32859C13.4762 2.2142 10.6374 5.95429 10.6374 10.2215L10.6374 14.2411L17.2122 14.2411L17.2122 8.5765L15.1902 8.5765C15.1417 8.57658 15.0936 8.56612 15.049 8.54576C15.0044 8.52541 14.9642 8.49559 14.9308 8.45813C14.8974 8.42066 14.8715 8.37634 14.8548 8.32788C14.838 8.27942 14.8308 8.22783 14.8334 8.17628C14.8354 8.13571 14.8892 7.17133 15.3635 5.98228C15.6341 5.29767 16.0027 4.66176 16.4562 4.09717C16.9467 3.49086 17.5336 2.98102 18.19 2.59094Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.42661 0.470251C8.4973 0.470278 8.56638 0.492638 8.6251 0.534494C8.68382 0.576352 8.72953 0.635819 8.75643 0.705356L9.49636 2.62083C9.53062 2.70954 9.53205 2.80875 9.50036 2.89853C9.46867 2.98831 9.40621 3.06201 9.32554 3.1048C8.05777 3.77738 7.12539 4.83665 6.55428 6.25297C6.3522 6.75461 6.20582 7.27961 6.11809 7.81733L8.09211 7.81733C8.18674 7.81733 8.27749 7.85732 8.34441 7.92851C8.41132 7.9997 8.44891 8.09624 8.44891 8.19692L8.44891 14.6207C8.44891 14.7213 8.41132 14.8179 8.34441 14.8891C8.27749 14.9603 8.18674 15.0002 8.09211 15.0002L0.802814 15.0002C0.708182 15.0002 0.617428 14.9603 0.550514 14.8891C0.483601 14.8179 0.446009 14.7213 0.446009 14.6207L0.446009 10.2215C0.442028 7.96623 1.16694 5.77803 2.49922 4.02375C3.8315 2.26946 5.69016 1.05573 7.76363 0.586024C8.14563 0.499431 8.38001 0.473334 8.38982 0.472148C8.40204 0.470806 8.41432 0.470174 8.42661 0.470251ZM8.71339 2.59094L8.2025 1.26856C8.12177 1.28351 8.02409 1.3032 7.91259 1.32859C3.99934 2.2142 1.15962 5.95429 1.15962 10.2215L1.15962 14.2411L7.7353 14.2411L7.7353 8.5765L5.71334 8.5765C5.6648 8.57658 5.61676 8.56612 5.57216 8.54576C5.52755 8.52541 5.48732 8.49559 5.45392 8.45813C5.42052 8.42066 5.39466 8.37634 5.37791 8.32788C5.36117 8.27942 5.35389 8.22783 5.35653 8.17628C5.35854 8.13571 5.41251 7.17133 5.88661 5.98228C6.15719 5.29767 6.5258 4.66177 6.97932 4.09717C7.46979 3.49073 8.05677 2.98087 8.71339 2.59094Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="cs-testimonial_meta_right">
                      <h3>Emilly </h3>
                      <p>Customer</p>
                      <div className="cs-review" data-review="4">
                        <img src="../../../img/icons/stars.svg" alt="Star" />
                        <div className="cs-review_in">
                          <img src="../../../img/icons/stars.svg" alt="Star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-pagination cs-style1"></div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
    </section>
  );
}
