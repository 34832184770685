import React from "react";

export default function RetailStores() {
  return (
    <section className="cs-gradient_bg_1">
      <div className="cs-height_95 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div
            className="cs-section_subtitle wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            Retail Stores
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <h3 className="cs-section_title">
          Versatile POS Software for Retailers 
          </h3>
        </div>
        <div className="cs-height_50 cs-height_lg_40"></div>
        <div className="row align-items-center flex-column-reverse-lg">
          <div className="col-xl-6">
            <div className="cs-left_full_width cs-space40">
              <div className="cs-left_sided_img">
                <img src="../../../img/retail-store.png" alt="Retail Stores" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="cs-height_25 cs-height_lg_40"></div>
            <div
              className="row wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="col-lg-11 offset-lg-1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cs-iconbox cs-style2 text-center">
                      <div className="cs-iconbox_icon">
                        <img
                          src="../../../img/icons/retail_icon_1.svg"
                          alt="Icon"
                        />
                      </div>
                      <h2 className="cs-iconbox_title">Food Delivery</h2>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="cs-iconbox cs-style2 text-center">
                      <div className="cs-iconbox_icon">
                        <img
                          src="../../../img/icons/retail_icon_2.svg"
                          alt="Icon"
                        />
                      </div>
                      <h2 className="cs-iconbox_title">Cycle Store</h2>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="col-lg-11">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cs-iconbox cs-style2 text-center">
                      <div className="cs-iconbox_icon">
                        <img
                          src="../../../img/icons/retail_icon_3.svg"
                          alt="Icon"
                        />
                      </div>
                      <h2 className="cs-iconbox_title">Gift Shop</h2>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="cs-iconbox cs-style2 text-center">
                      <div className="cs-iconbox_icon">
                        <img
                          src="../../../img/icons/retail_icon_4.svg"
                          alt="Icon"
                        />
                      </div>
                      <h2 className="cs-iconbox_title">Furniture Store</h2>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="col-lg-11 offset-lg-1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cs-iconbox cs-style2 text-center">
                      <div className="cs-iconbox_icon">
                        <img
                          src="../../../img/icons/retail_icon_5.svg"
                          alt="Icon"
                        />
                      </div>
                      <h2 className="cs-iconbox_title">Clothing Store</h2>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="cs-iconbox cs-style2 text-center">
                      <div className="cs-iconbox_icon">
                        <img
                          src="../../../img/icons/retail_icon_6.svg"
                          alt="Icon"
                        />
                      </div>
                      <h2 className="cs-iconbox_title">Coffee Shop</h2>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs-height_75 cs-height_lg_70"></div>
    </section>
  );
}
